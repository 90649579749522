import React from 'react';
import {Button} from '../../common/components/ui/button/Button';

interface ItemTariffCardProps 
{
    id: string;
    title: string;
    description: string;
    isActive: boolean;
    hasClicked: boolean;
    onButtonClick: (id: string) => void;
}
const ItemCard:React.FC<ItemTariffCardProps> = ({ id, title, description, isActive, hasClicked, onButtonClick }) => 
{
   


    return (
        <li className={`tariff__card ${isActive ? 'active-card' : ''} ${hasClicked && !isActive ? 'inactive-card' : ''}`}>
            <div className="tariff__cardIcon">
                <svg  className='moon' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" fill="#E3CDF6"/>
                    <path d="M39.9997 77.863C60.9109 77.863 77.8627 60.9111 77.8627 40C77.8627 19.0888 60.9109 2.13699 39.9997 2.13699C19.0886 2.13699 2.13672 19.0888 2.13672 40C2.13672 60.9111 19.0886 77.863 39.9997 77.863Z" stroke="white" strokeWidth="4.27391"/>
                    <path d="M30.4961 45.1412H48.1347V52.8101H30.4961V45.1412Z" fill="white"/>
                    <path d="M48.7484 46.7516C48.7484 44.2296 47.7546 41.8108 45.9856 40.0274C44.2166 38.244 41.8173 37.2422 39.3156 37.2422C36.8139 37.2422 34.4146 38.244 32.6456 40.0274C30.8766 41.8108 29.8828 44.2296 29.8828 46.7516H39.3156H48.7484Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M39.3532 49.129C44.7533 49.129 49.131 44.7513 49.131 39.3511C49.131 33.9509 44.7533 29.5732 39.3532 29.5732C33.953 29.5732 29.5753 33.9509 29.5753 39.3511C29.5753 44.7513 33.953 49.129 39.3532 49.129ZM39.3532 56.7979C48.9888 56.7979 56.7999 48.9867 56.7999 39.3511C56.7999 29.7155 48.9888 21.9043 39.3532 21.9043C29.7176 21.9043 21.9062 29.7155 21.9062 39.3511C21.9062 48.9867 29.7176 56.7979 39.3532 56.7979Z" fill="white"/>
                </svg>
            </div>
            <h3 className="tariff__cardTitle">{title}</h3>
            <span className="tariff__cardDescription">{description}</span>
            <Button
                className={`small ${isActive ? 'active' : ''} ${hasClicked && !isActive ? 'no-active' : ''}`}
                onClick={() => onButtonClick(id)}
            >
                {isActive ? 'Selected' : 'Choose'}
            </Button>            
        </li>
    );
};

export default ItemCard;