import React from 'react';
import LayerPage from '../../common/LayerPage/LayerPage';
import {WelcomeBanner}  from '../../modules/SetupAccount';

const WelcomeBanerPage = () => {
    return (
        <LayerPage showGroup={false} >
            <WelcomeBanner/>
        </LayerPage>
    );
};

export default WelcomeBanerPage;