import React, {useState} from 'react';
import {useForm, SubmitHandler, SubmitErrorHandler, Controller} from 'react-hook-form'
import { Input } from '../ui/input/Input';
import {Button} from '../ui/button/Button';
import './Modal.scss'
interface ModalProps
{
    active: any
    setActive: (value: boolean) => void;

}
interface MyForm
{
    name: string;
    contact: string;
    email: string;
    Telegram: string;
    Phone: string;
    description: string;
    contactMethod: string;
}
export const Modal: React.FC<ModalProps> = ({active, setActive}) => 
{
    const {register, handleSubmit, control, formState:{errors}, watch} = useForm<MyForm>()
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const selectedOption = watch("contactMethod");
    const submit: SubmitHandler<MyForm> = data =>
    {
        console.log(data)
    }  
    const error: SubmitErrorHandler<MyForm> = data =>
    {
        console.log(data)
        console.log(errors.contact)
    }  

    return (
        <div className={active ? 'modalSupport active-modal' : 'modalSupport'}>
            <div className="modalSupport__header">
                <span className="modalSupport__header-title">Open a new ticket</span>
                <div className="modalSupport__header-close" onClick={() => setActive(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18" stroke="#ADABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 6L18 18" stroke="#ADABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>                    
                </div>
            </div>

            <form className='modalSupport__form' onSubmit={handleSubmit(submit, error)}>
                <label className='form__label'>
                    <span className={`form__title ${errors.name ? 'error' : ''}`}>{errors.name ? 'Fill in this field' : 'Enter your name'}</span>
                    <Controller
                    name='name'
                    rules={{required: true}}
                    control={control}
                    render={({field}) => <Input className={`basic ${errors.name ? 'error' : ''}`} {...field}/>}/>
                </label>
                <label className='form__label'>
                    <span className={`form__title ${errors.contact ? 'error' : ''}`}>
                                {errors.contact?.type === 'required' && 'Fill in this field'}
                                {errors.contact?.type === 'pattern' && errors.contact.message}
                                {errors.contact?.type === 'minLength' && errors.contact.message}
                                {!errors.contact && 'Select to contact you'}
                        </span>
                    <ul className="form__contact">
                        <li className="form__contact-item">
                            <label className="checkbox-circle" onChange={() => setIsChecked(true)}>
                                <input type="radio" defaultValue='email' className="checkbox-input" {...register('contactMethod' , {required: true})}/>
                                <span className="checkbox-indicator-circle">
                                    <svg className="checkbox-indicator-circle-active" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#7B76FF"></circle>
                                    </svg>
                                </span>
                            </label>
                            <span className="form__contact-title">Email</span>                                                        
                        </li>
                        <li className="form__contact-item">
                            <label className="checkbox-circle">
                                <input type="radio" defaultValue='telegram' className="checkbox-input" {...register('contactMethod' , {required: true})}/>
                                <span className="checkbox-indicator-circle">
                                    <svg className="checkbox-indicator-circle-active" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#7B76FF"></circle>
                                    </svg>
                                </span>
                            </label>    
                            <span className="form__contact-title">Telegram</span>                                                    
                        </li>
                        <li className="form__contact-item">
                            <label className="checkbox-circle">
                                <input type="radio" defaultValue='phone' className="checkbox-input" {...register('contactMethod' , {required: true})}/>
                                <span className="checkbox-indicator-circle">
                                    <svg className="checkbox-indicator-circle-active" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="4" cy="4" r="4" fill="#7B76FF"></circle>
                                    </svg>
                                </span>
                            </label>
                            <span className="form__contact-title">Phone</span>                                                        
                        </li>
                    </ul>
      <Controller
        name="contact"
        control={control}
        defaultValue="" 
        rules={
            
          selectedOption === "email"
            ? {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }
            : selectedOption === "telegram"
            ? {
                required: "Telegram username is required",
                minLength: {
                  value: 5,
                  message: "Username must be at least 5 characters long",
                },
              }
            : selectedOption === "phone"
            ? {
                required: "Phone number is required",
                pattern: {
                  value: /^\+?[0-9]{10,15}$/,
                  message: "Invalid phone number",
                },
              }
            : {required: true} // No rules if no option selected (though this shouldn't happen)
        }
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            className={`basic ${errors.contact ? 'error' : ''}`}
            placeholder={
              selectedOption === "email"
                ? "Enter email"
                : selectedOption === "telegram"
                ? "Enter Telegram username"
                : selectedOption === "phone"
                ? "Enter phone number"
                : ""
            }
          />
        )}
      />
    
                   
                </label>
                <label className="form__label">
                    <span  className={`form__title ${errors.description ? 'error' : ''}`}>{errors.description ? 'Fill in this field' : 'Describe your problem'}</span>
                    <textarea className={`form__textArea ${errors.description ? 'error' : ''}`} placeholder='Text' {...register('description' , {required: true})}/>
                </label>
                <Button className='button-modal'>OK</Button>
            </form>
        </div>
    );
};