import React, {useEffect, useMemo, useState, useRef, memo} from 'react';
import ItemQuestion from './ItemQuestion';
import ItemResponse from './ItemResponse';
import {ItemModules} from './ItemModules';
import {ReactComponent as IconModuleTelegram} from '../../assets/icon/IconModuleTelegram.svg'
import {ReactComponent as IconModuleVoiceX} from '../../assets/icon/IconModuleVoiceX.svg'
import {ReactComponent as IconModuleAI} from '../../assets/icon/IconModuleAI.svg'
import {ReactComponent as IconModuleAutoAnswer} from '../../assets/icon/IconModuleAutoAnswer.svg'
import {ReactComponent as IconModuleCRM} from '../../assets/icon/IconModuleCRM.svg'
import {ReactComponent as IconModuleCampaigns} from '../../assets/icon/IconModuleCampaigns.svg'
import {ReactComponent as IconModuleClick2Call} from '../../assets/icon/IconModuleClick2Call.svg'
import {ReactComponent as IconModuleDelivery} from '../../assets/icon/IconModuleDelivery.svg'
import {ReactComponent as IconModuleEvents} from '../../assets/icon/IconModuleEvents.svg'
import {ReactComponent as IconModuleFax} from '../../assets/icon/IconModuleFax.svg'
import {ReactComponent as IconModuleOffice} from '../../assets/icon/IconModuleOffice.svg'
import {ReactComponent as IconModuleRDP} from '../../assets/icon/IconModuleRDP.svg'
import {ReactComponent as IconModuleSMS} from '../../assets/icon/IconModuleSMS.svg'
import {ReactComponent as IconModuleVPN} from '../../assets/icon/IconModuleVPN.svg'
import {ReactComponent as IconModuleWhatsapp} from '../../assets/icon/IconModuleWhatsapp.svg'
import './SurveyChat.scss'

interface chatProps 
{
    response: boolean;
    responseMessage: {id: number; text: string} | null;
    onSelectPlatform: (value: string) => void;
    onRepeatMessage: (value: string) => void;
}
interface RadioOptionsProps 
{
    options: string[] | undefined;
    onSelect: (selectedOption: string) => void;
}
interface historyChatProps 
{
    type: 'question' | 'response' | 'radio' | 'loader' | 'modules';
    message: string | string[];
    options?: string[];
}
const RadioOptions: React.FC<RadioOptionsProps> = ({ options, onSelect }) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedOption(value);
        onSelect(value);
    };

    return (
        <li className="survey__options">
            <div className="platform">
                <span className="platform__title">Please select one:</span>
                <ul className="platform__list">
                    {options?.map((option, index) => (
                        <li className='platform__item' key={index}>
                            <label className="checkbox-circle">
                                <input 
                                    type="radio" 
                                    className="checkbox-input" 
                                    value={option}
                                    checked={selectedOption === option}
                                    onChange={handleOptionChange}/>
                                <span className="checkbox-indicator-circle" >
                                    <svg className="checkbox-indicator-circle-active" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#7B76FF">
                                        </circle>
                                    </svg>
                                </span>
                            </label>            
                            <span className="platform__name">{option}</span>            
                        </li>
                    ))}                
                </ul>
            </div>            
        </li>
    );
};

const InputDataLoader = () =>
{
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const handleIconClick = () => 
    {
        fileInputRef.current && fileInputRef.current.click();
        
    };
       // Обработчик перетаскивания файла
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);

        // Получаем файлы из события drop
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            handleFiles(files);
        }
    };

    // Обработчик выбора файлов через input
    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            handleFiles(files);
        }
    }; 
        // Обработка файлов
    const handleFiles = (files: FileList) => {
        for(let i = 0; i < files.length; i++)
        {
            const file = files[i];

            if(!file.name.endsWith('.xlsx'))
            {
                alert('Пожалуйста, выберите файл с расширением .xlsx');
            }
        }
    };
    return(
        <li className="survey__loader">
            <div 
                className={`loader ${isDragging ? 'loader__dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleIconClick}
            >
                <div className="loader__icon" onClick={handleIconClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="40" viewBox="0 0 63 40" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.2362 16.8332C51.2362 16.6667 51.2638 16.5001 51.2638 16.3336C51.2638 7.30583 44.0692 0 35.1945 0C28.7914 0 23.2912 3.80528 20.7083 9.30556C19.5831 8.73583 18.3195 8.40278 16.9859 8.40278C12.8887 8.40278 9.47173 11.4442 8.81909 15.4167C3.90284 17.111 0.388672 21.8196 0.388672 27.3611C0.388672 34.3332 5.95839 40 12.8192 40H27.0553V28.8889H20.361L31.4998 17.264L42.6387 28.8747H35.9442V39.9858H51.2638C57.5414 39.9858 62.6109 34.7775 62.6109 28.4028C62.6109 22.0279 57.5138 16.8474 51.2362 16.8332Z" fill="#ADABFF"/>
                    </svg>                    
                </div> 
                <input 
                    ref={fileInputRef}
                    className="loader__input-file" 
                    accept='.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                    type="file" />
                <span className="loader__title">
                    Drag an file here or <br /> <span className="loader__link">select file with numbers</span>                        
                </span>   
            </div>            
        </li>
    )
}
const SurveyChat:React.FC<chatProps> = memo(({response, responseMessage, onSelectPlatform, onRepeatMessage}) => 
{
    const [chatHistory, setChatHistory] = useState<Array<historyChatProps>>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const questions = [
        'Please type your phone number',
        'Enter the verification code',
        'Okay! Now type your email please',
        'Which social media platform would you prefer for future communication?',       
        'A confirmation code has been sent to your telegram, please enter it',
        'What’s your company name?',
        'Will you use your own phone numbers or ours?',
        'Okay. Please select modules you want to use'
    ]
    const modules = [
        'VoiceX CC', 'Telegram', 'WhatsApp', 'CRM', 'RDP', 
        'Fax', 'SMS', 'Events', 'AI', 'VPN', 
        'Evening autoanswer', 'Click2call', 'Campaigns', 'Office 365', 'Delivery Agents'
    ]

const icons: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    'voicex-cc': IconModuleVoiceX,
    'telegram': IconModuleTelegram,
    'whatsapp': IconModuleWhatsapp,
    'crm': IconModuleCRM,
    'rdp': IconModuleRDP,
    'fax': IconModuleFax,
    'sms': IconModuleSMS,
    'events': IconModuleEvents,
    'ai': IconModuleAI,
    'vpn': IconModuleVPN,
    'evening-autoanswer': IconModuleAutoAnswer,
    'click2call': IconModuleClick2Call,
    'campaigns': IconModuleCampaigns,
    'office-365': IconModuleOffice,
    'delivery-agents': IconModuleDelivery,
};;
    useMemo(() =>
    {   
        setTimeout(() =>
        {   
            setChatHistory([{type: 'question', message: 'Write your first and last name' }]); // Initial question
        }, 2000)
    }, [])

    useEffect(() =>
    {
        if(response && responseMessage)
        {   
                
            setChatHistory(prev => [...prev, { type: 'response', message: responseMessage.text}]);
            const delayTimer = setTimeout(() => { // Задержка перед следующим вопросом
                if (currentQuestionIndex < questions.length) 
                {
                    const nextQuestion = questions[currentQuestionIndex];
                    setChatHistory(prev => [...prev, {type: 'question', message: nextQuestion }]);
                    if(nextQuestion === 'Which social media platform would you prefer for future communication?')
                    {
                        setChatHistory(prev => [...prev, {type: 'radio', message: nextQuestion, options: ['WhatsApp', 'Telegram', 'Email'] }]);
                    }
                    else if(nextQuestion === 'Will you use your own phone numbers or ours?')
                    {
                        setChatHistory(prev => [...prev, {type: 'radio', message: nextQuestion, options: ['Use your own', 'Use ours'] }]);
                    }
                    else if(nextQuestion === 'Okay. Please select modules you want to use')
                    {
                        setChatHistory(prev => [...prev, {type: 'modules', message: nextQuestion}]);
                    }
                    setCurrentQuestionIndex(prev => prev + 1);
                }
            }, 0);
             return () => clearTimeout(delayTimer);
        }
    }, [response, responseMessage])

    const handleRadioSelect = (selectedOption: string) => {
            let question = ''
            if(selectedOption === 'Use your own')
            {
                setChatHistory(prev => [...prev, { type: 'loader', message:['Our team will contact you to clarify information on the numbers.','There s still a little left! Write how many operators you have?'] }]);
            }
            onSelectPlatform(selectedOption)
            switch(selectedOption)
            {
                case 'WhatsApp':
                    question = 'phone number on WhatsApp?'
                    break
                case 'Telegram':
                    question = 'phone number or nickname for contact on Telegram?'
                    break
                case 'Email':
                    question = 'email adress?'
                    break
            }

            question && setChatHistory(prev => [...prev, {type: 'question', message: `What is your ${question}` }]);
            selectedOption === 'Email' && onRepeatMessage(chatHistory[7].message as string);
        };
    return (
        <div className="survey">
           <ul className='survey__list-left'>

                {chatHistory.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.type === 'question' && <ItemQuestion  question={item.message} />}
                        {item.type === 'response' && <ItemResponse  response={item.message} />}
                        {item.type === 'radio' && (
                            <div className="radio-options-container" >
                                <RadioOptions options={item.options} onSelect={handleRadioSelect} />
                            </div>
                        )}
                        {item.type === 'loader' && (
                            
                            <>
                                <InputDataLoader/>
                                <ItemQuestion question={item.message[0]}/>
                                <ItemQuestion question={item.message[1]}/>
                            </>
                        )}
                        {item.type === 'modules' && 
                             <div className="modules">
                                <ul className="modules__wrapper">
                                    {modules.map((item) => {
                                        const iconKey = item.toLowerCase().replace(/ /g, '-');
                                        const IconComponent = icons[iconKey];

                                        return(
                                            <ItemModules  
                                            key={item}
                                            nameModule={item}
                                            icon={IconComponent || (() => null)}
                                            />
                                        );
                                    })}
                                </ul>
                            </div> 
                   }
  
                    </React.Fragment>
                ))}
           </ul>
        </div>
    );
});

export default SurveyChat;