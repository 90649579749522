import React from 'react';
import {Button} from '../../../../common/components/ui/button/Button';
import {TariffCards} from '../../../../components/TariffCard/TariffCards';
import { Link } from 'react-router-dom';
import './WelcomeBanner.scss'

const WelcomeBanner = () => 
{
    return (
        <div className="banner">
            <h1 className='banner__title '>Hello! Here we are going to set up <br/> your <span className='banner__title color-violet'>call center account.</span> </h1>
            <h3 className='banner__subtitle'>You just need to answer few questions about your business. </h3>
            <TariffCards/>
            <Link to="/setupAccount"><Button className='medium'>Start now?</Button></Link>
        </div>
    );
};

export default WelcomeBanner;