import React from 'react';
import './Modal.scss'
interface ModalProps {
    active: boolean;
    setActive: (value: boolean) => void;
    setConfirmationDelete: (value: boolean) => void;
}
const Modal: React.FC<ModalProps> = ({active, setActive, setConfirmationDelete}) => {
    // console.log('%c Modal', 'color: green; background: white',active)
    const handlerContinueDelete = () =>
    {
        setActive(false)
        setConfirmationDelete(true)
    }
    return (
        <div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)} >
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                <div className="modal__title">Please wait</div>
                <div className="modal__description">
                    Are you sure this will lead to the position being deleted?
                </div>
                <div className="modal__buttons-wrapper">
                    <button className="modal__button-back" 
                            onClick={() => setActive(false)}>Back</button>
                    <button className="modal__button-continue"
                            onClick={handlerContinueDelete}>Continue</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;