import React from 'react';

const ItemQuestion = ({question}:{ question: string | string[] }) => 
{

    return (
            <li className="survey__question">
                <div className="survey__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="26" viewBox="0 0 48 26" fill="none">
                    <path d="M2.39844 0.861578C2.39844 1.75192 18.5718 23.0265 20.3856 24.4791C21.746 25.6038 22.5521 25.8849 24.366 25.8849C25.6256 25.8849 27.0867 25.6506 27.5906 25.4163C28.699 24.854 43.0081 6.43795 46.4847 1.09588C46.8373 0.533556 46.0312 0.486696 41.7485 0.674137C37.5163 0.861578 36.5086 1.04902 35.904 1.79878C35.5009 2.26739 32.7298 5.92249 29.8075 9.85875L24.4164 17.0284L22.0987 14.0293C20.8391 12.3892 18.0679 8.68724 16.0022 5.82876L12.2234 0.627277L7.33609 0.580418C4.61534 0.580418 2.39844 0.720997 2.39844 0.861578Z" fill="#7B76FF"/>
                    <path d="M18.0442 1.03048C17.4906 1.35917 18.2455 2.627 21.5671 7.08787C23.8821 10.234 26.0462 12.7696 26.2978 12.7696C26.9521 12.8166 31.0789 7.04092 31.0789 6.10178C31.0789 5.72613 30.0724 4.36439 28.8645 3.14352L26.6501 0.842652L22.7246 0.701781C20.5102 0.654825 18.4468 0.748739 18.0442 1.03048Z" fill="#7B76FF"/>
                    <path d="M36.327 16.2945C35.4715 17.23 34.4147 18.68 33.9115 19.5687C33.0057 21.1122 33.0057 21.2058 33.9618 22.8429C35.5219 25.5558 36.4277 25.8832 42.2652 25.7429L47.3983 25.6026L42.9195 20.13C40.5039 17.0896 38.34 14.6574 38.1387 14.6106C37.9877 14.6106 37.1322 15.359 36.327 16.2945Z" fill="#7B76FF"/>
                    </svg>                        
                </div>
                <div className="survey__question-wrapper">
                    <span className="survey__question-text">{question}</span>
                </div>
            </li>           
    );
};

export default ItemQuestion;