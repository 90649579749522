import React from 'react';
import LayerPage from '../../common/LayerPage/LayerPage';
import  {SetupForm}  from '../../modules/SetupAccount';
const SetupAccountPage = () => {
    return (
        <LayerPage showGroup={false}>
            <SetupForm/>
        </LayerPage>
    );
};

export default SetupAccountPage;