import {configureStore} from '@reduxjs/toolkit';
import chatBotReducer from '../../modules/chatBot/chatBotSlice';
import { useDispatch } from 'react-redux';

// export default configureStore({
//     reducer: {
//         bot: chatBotReducer
//     },
// });
interface Tariff {
    type: string; // <- Указываем, что type - строка
    // ... другие свойства тарифа
}
export interface Country {
    id?: number | undefined;
    Country?: string;
    Tariffs: Tariff[];
}

interface BotState {
    status: string | null;
    error: string | null;
    localStorageIdCountry: any[]; //  Уточните тип, например: number[] | string[]
    country: { Data: Country[] };// Уточните тип, например: { Data: Country[] }
    openMap: boolean | null;
}

export interface RootState {  // <- Интерфейс для всего store
    bot: BotState;
    // ... другие слайсы, если есть:  example: user: UserState;
}

const store = configureStore({
    reducer: {
        bot: chatBotReducer,
        // ... другие reducer'ы
    },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;