import React, {useState, useCallback, useRef} from 'react';
import {Button} from '../../../../common/components/ui/button/Button'
import SurveyChat from '../SurveyChat/SurveyChat';
import UserCard from '../UserCard/UserCard';
import './SetupForm.scss'

interface responseProps 
{
    answerName: string
}

export const SetupForm = () => 
{
    // const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null)
    const [message, setMessage] = useState<{id: number; text: string;} | null>(null);
    const [selectedPlatform, setSelectedPlatform] = useState<string>('')
    const [repeatMessage, setRepeatMessage] = useState<string>('')
    const [response, setResponse] = useState<boolean>(false)
    const [currentResponse, setCurrentResponse] = useState<Array<responseProps>>([])
    const [currentResponseIndex, setCurrentResponseIndex] = useState<number>(0);
    // console.log('Setup перерендер')

    const hadnleSubmut = useCallback(() =>
    {  
        const responses = ['name', 'number', 'code', 'email', 'platform', 'confirmCode', 'companyName', 'useMyNumbers'];
        if(inputRef.current && inputRef.current.value.length !== 0)
        {
            setMessage({id: Date.now(), text: inputRef.current?.value})
            setResponse(true)
            const nextResponse = responses[currentResponseIndex]
            setCurrentResponse(prev => [...prev, {answerName: nextResponse}])
            setCurrentResponseIndex(prev => prev + 1);
            inputRef.current.value = '';
            setRepeatMessage('')
        }
    },[currentResponseIndex])

    return (
        <div className="chatForm">
            <div className="chatForm__body">
                <div className="chatForm__leftPart">
                    <SurveyChat
                        response={response}
                        responseMessage={message}
                        onSelectPlatform={setSelectedPlatform}
                        onRepeatMessage={setRepeatMessage}/>
                </div>
                <div className="chatForm__rightPart">
                    <UserCard
                        selectedPlatform={selectedPlatform}
                        currentResponse={currentResponse}/>
                </div>
            </div>
            <div className="chatForm__bottom">
                <input 
                    ref={inputRef}
                    value={repeatMessage !== '' ? repeatMessage : undefined}
                    className='chatForm__input' 
                    type="text"
                    placeholder='Send a message' />
                <Button
                    onClick={hadnleSubmut}
                    className='small-form'
                    >Send</Button>
            </div>
        </div>
    );
};