import React, {useState, useEffect} from 'react';

import './TrashBasket.scss'
interface TrashBasketListProps {
    id: number; // или string, если id - строка
    country: { Country: string; id: number }; // Уточните тип country
    handleDeleteClick: (id: number) => void; // Уточните типы аргументов и возвращаемого значения
    typeRoom: { type: string; }[]; // Уточните тип typeRoom
    numberCounter: number[]; // или number, если это одно число
    nameCountry: string;
    indexRoom: number;
    setNumberAndType: (value: any) => void; // Уточните тип value
    widthParent: number;
    setModalActive: (value: boolean) => void;
    stateConfirmationDelete: boolean;
    setConfirmationDelete: (value: boolean) => void;
}

const TrashBasketList: React.FC<TrashBasketListProps> = ({id, country, handleDeleteClick, typeRoom, numberCounter,  nameCountry, indexRoom, setNumberAndType, widthParent, setModalActive, stateConfirmationDelete, setConfirmationDelete}) => {

    const url = `${country.Country}.svg`;
    const typeAndNumberCount = JSON.parse(localStorage.getItem('numberCount') ?? 'null') || [];
    const [count, setCount] = useState(numberCounter);
    const [targetIndex, setTargerIndex] = useState<number>();

    const  incrementCount = (e:any) =>
    {  
        const index = +e.currentTarget.getAttribute('count-index');
        const updateCount = [...count];
        updateCount[count.length === 1 ? 0 : index] += 1;
        setCount(updateCount);
       updateLocalStorage(typeRoom[index].type, updateCount[count.length === 1 ? 0 : index])
    }

    const decrementCount = (e:any) =>
    { 
        const index = +e.currentTarget.getAttribute('count-index');
        // console.log('index',index)
        setTargerIndex(index)
        if(count[index] !== 0)
        {
            const updateCount = [...count]
            if(count[count.length === 1 ? 0 : index] !== 1)
            {
                updateCount[count.length === 1 ? 0 : index] -= 1;
                setCount(updateCount)
            }

            if(count[count.length === 1 ? 0 : index] === 1)
            {
                setModalActive(true)
            }
            else
            {
                updateLocalStorage(typeRoom[index].type, updateCount[count.length === 1 ? 0 : index])
            } 
        }

    }

    useEffect(() =>
    {
        setCount(numberCounter)
    }, [numberCounter])



    const filteredResults = typeRoom.map((item, index) => {
        const matchingCountry = typeAndNumberCount.find((country:any) => country.TypeRoom === item.type && country.country === nameCountry);
        if (matchingCountry && matchingCountry.num !== 0) 
            {
             return { ...item, countIndex: index, num: matchingCountry.num, price: matchingCountry.price };
            }
        return null;
    }).filter(item => item !== null);

    const updateLocalStorage = (typeRooms:any, newNum:any) =>
    { 
        const updatedStorage = typeAndNumberCount.map((item:any) =>
            {
                if(item.TypeRoom === typeRooms && item.country === nameCountry)
                {

                    return {...item, id: id, num: newNum}
                }
                return item
            }
        )
         
        const isChanged = JSON.stringify(typeAndNumberCount) !== JSON.stringify(updatedStorage)
        const matchId = updatedStorage.filter((country:any) => country.num === 0)
        if(isChanged)
        {
            setNumberAndType(updatedStorage) 
        }
        if(filteredResults.length === 1 && matchId.length > 0)
        {   
            // handleDeleteClick(matchId[0].id)
        }
    }


    
    useEffect(() =>
    {
        // console.log(stateConfirmationDelete)
        if(stateConfirmationDelete && targetIndex !== undefined && typeRoom !== undefined)
        {
            console.log(targetIndex)
            console.log(count)
            console.log(typeRoom?.[targetIndex]?.type,)
            // updateLocalStorage(typeRoom[targetIndex].type, count[count.length === 1 ? 0 : targetIndex] === 1 ? 0 : '')
            updateLocalStorage(typeRoom[targetIndex].type, count[count.length === 1 ? 0 : targetIndex] === 1 ? 0 : '')
        }
        setConfirmationDelete(false)
    }, [count, typeRoom, stateConfirmationDelete])
// console.log(filteredResults)
    return (
            <div className="trashBasket__selected-country" style={{width: `${widthParent - 9}px`}}>
                <div className="trashBasket__item">
                    <div className="trashBasket__header">
                        <div className="trashBasket__left-column-header">
                        <div className="trashBasket__icon">
                            <img src={require(`../../../../assets/icons/trashCan-country/${url}`)} alt={`Flag of ${country.Country}`} />
                        </div>
                        <div className="trashBasket__title">{country.Country.replace(/^(\w)/, c => c.toUpperCase())}</div>
                        </div>
                        <button onClick={() => handleDeleteClick(country.id)} 
                                className="trashBasket__trash-can"
                                >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M3 6H5H21" stroke="#ADABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#ADABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10 11V17" stroke="#ADABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14 11V17" stroke="#ADABFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    <div className="trashBasket__body">
                       {filteredResults.map((item:any, index) => (   

                            <div key={index} className="trashBasket__type-rooms">
                            <div className="trashBasket__name-type">{item.type}</div>
                            <div className="trashBasket__right-column-body">
                                <div className="trashBasket__counter">
                                    <div onClick={(e) => decrementCount(e)}
                                        count-index={item.countIndex} 
                                        className="trashBasket__decrease">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                            <path d="M18 12.5H6C5.45 12.5 5 12.05 5 11.5C5 10.95 5.45 10.5 6 10.5H18C18.55 10.5 19 10.95 19 11.5C19 12.05 18.55 12.5 18 12.5Z" fill="#ADABFF"/>
                                        </svg>
                                    </div>
                                    <div className="trashBasket__number">{count[count.length === 1 ? 0 : item.countIndex]}
                                    </div>
                                    <div onClick={(e) => incrementCount(e)} 
                                        className="trashBasket__increase"
                                        count-index={item.countIndex}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                            <path d="M18 12.5H13V17.5C13 18.05 12.55 18.5 12 18.5C11.45 18.5 11 18.05 11 17.5V12.5H6C5.45 12.5 5 12.05 5 11.5C5 10.95 5.45 10.5 6 10.5H11V5.5C11 4.95 11.45 4.5 12 4.5C12.55 4.5 13 4.95 13 5.5V10.5H18C18.55 10.5 19 10.95 19 11.5C19 12.05 18.55 12.5 18 12.5Z" fill="#ADABFF"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="trashBasket__price">{item.price}$</div>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="trashBasket__line">
                        <hr />
                    </div>
                </div>
            </div>
    );
};

export default TrashBasketList;