import {createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';


interface AsyncThunkConfig {
    rejectValue: string; // Тип для rejectValue
}
export const fetchCountry = createAsyncThunk<CountryResponse, void, AsyncThunkConfig>(
    'chatBot/fetchCountry',
    async (_, { rejectWithValue }) => {
        // // const response = await axios.api.getCountry();
        // // return await response;
        // const responce = await fetch('https://pay.voicex.biz:7260/api/Countrys');
        // const data = await responce.json();
        // return data;
                try {
            const response = await fetch('https://pay.voicex.biz:7260/api/Countrys');
            if (!response.ok) {
                const errorData = await response.json(); // Попытка получить детали ошибки с сервера
                return rejectWithValue(errorData?.message || 'Ошибка загрузки страны'); // Передаем сообщение об ошибке в rejectWithValue
            }
            const data = await response.json();
            return data as CountryResponse; // Явное приведение типов к CountryResponse
        } catch (error: any) { // Типизируем error
            return rejectWithValue(error?.message || 'Ошибка'); // Передаем сообщение об ошибке в rejectWithValue
        }
    })
interface Country 
{
    id?: number; 
    Country?: string; 
    // Data?: Country[]; 
}
interface CountryResponse 
{
    Data: Country[];
}
interface BotState { // Интерфейс для всего состояния слайса
    status: string | null;
    error: string | null;
    localStorageIdCountry: any[]; // или number[] или string[]
    country: { Data: Country[] } | []; // или Country[] или Type of country
    openMap: boolean | null;
}
const initialState: BotState = { // Типизируем initialState
    status: null,
    error: null,
    localStorageIdCountry: [],
    country: [], // или { Data: [] } если country всегда должен иметь Data
    openMap: null,
}

const chatBotSlice = createSlice({
    name: 'chatBot',
    initialState,
    
    reducers: {

        isOpenMap(state: any, action)
        {
            state.openMap = action.payload < 3 ? true : false;
        },
        postIdCounry(state, action)
        {
            if(state.localStorageIdCountry.length === 0)
            {
            }
            state.localStorageIdCountry = action.payload;
        }
    },
    extraReducers: (builder) =>
    {
        builder 
            .addCase(fetchCountry.pending, (state) =>
            {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCountry.fulfilled, (state, action: PayloadAction<CountryResponse>) =>
            {
                state.status = 'resolved';
                state.country = 
                {
                    ...action.payload,
                    Data: action.payload.Data.map((country, index) => ({
                        ...country,
                        id: index + 1 
                        }))
                };
            })
            .addCase(fetchCountry.rejected, (state, action: PayloadAction<string | undefined>) =>
            {
                state.status = 'rejected';
                state.error = action.payload || 'Ошибка';
            })
    }
})
export const {isOpenMap, postIdCounry} = chatBotSlice.actions;
export default chatBotSlice.reducer;