import React, {useState} from 'react';
import {Button} from '../../common/components/ui/button/Button'
import ItemCard from './ItemCard';
import {TariffProps} from './Interfaces'
import './TariffCards.scss'


export const TariffCards: React.FC<TariffProps> = ({ onButtonClick }) => 
{
    const [activeButtonId, setActiveButtonId] = useState<string | null>(null); // Состояние для хранения ID активной кнопки
    const [hasClicked, setHasClicked] = useState<boolean>(false);
        // Обработчик нажатия на кнопку
    const handleButtonClick = (id: string) => 
    {
        setActiveButtonId(id); // Устанавливаем ID активной кнопки
        setHasClicked(true)
    };
    const tariffs = 
    [
        { id: 'basic', title: 'Basic', description: 'Brief descriptionBrief descriptionBrief descriptionBrief descriptionBrief description' },
        { id: 'starter', title: 'Starter', description: 'Brief descriptionBrief descriptionBrief descriptionBrief descriptionBrief description' },
        { id: 'professional', title: 'Professional', description: 'Brief descriptionBrief descriptionBrief descriptionBrief descriptionBrief description' },
    ];
    return (
        <div className='tariff'>
            <ul className="tariff__cardsList">

                {tariffs.map(tariff => 

                        <ItemCard
                            key={tariff.id}
                            id={tariff.id}
                            title={tariff.title}
                            description={tariff.description}
                            isActive={activeButtonId === tariff.id}
                            hasClicked={hasClicked} 
                            onButtonClick={handleButtonClick}/>
  
                )}
            </ul>
        </div>
    );
};
