import React, {useState, useRef} from 'react';
import { LayerProps } from "./Interfaces";
import Header from "../components/header/Header";
import {Modal} from '../components/ModalSupport/Modal';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import './LayerPage.scss'


const LayerPage: React.FC<LayerProps> = ({children, showGroup = true}) =>
{
    const tawkMessengerRef = useRef<any>(null);
    const [modalActive, setModalActive] = useState<boolean>(false)

    const onBeforeLoad  = () => {
        tawkMessengerRef.current.hideWidget();
    };
    const handleOpenChat = () => 
    {
        if(tawkMessengerRef.current)
        {
            tawkMessengerRef.current.maximize()
        }
    };
    return (
        <div className="LayerPage" >
            <Header/>
           <main className={showGroup ? 'container' : 'container-form'}>
                {children}
           </main>
            {showGroup ? <div className="group-message" onClick={handleOpenChat}></div> : ''}
            {showGroup ?
                <div className="group-phone">
                     <div className="group-circle">
                        <a href="https://t.me/VoiceXSupportBot"
                            telegram-tooltip="Telegram">
                            <div className='group-telegram'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                <path d="M40.7139 4.50133C40.5008 4.31737 40.2416 4.19503 39.9642 4.14748C39.6868 4.09993 39.4016 4.12897 39.1395 4.23149L4.30732 17.8629C3.81367 18.0549 3.39573 18.402 3.11644 18.852C2.83714 19.3021 2.7116 19.8306 2.75871 20.3582C2.80583 20.8858 3.02305 21.3837 3.37767 21.7772C3.73229 22.1706 4.20512 22.4382 4.72497 22.5396L13.7501 24.3116V34.3749C13.7484 34.9231 13.9112 35.4591 14.2177 35.9136C14.5242 36.368 14.96 36.72 15.4689 36.9238C15.9769 37.1313 16.5356 37.1811 17.0724 37.0668C17.6091 36.9524 18.099 36.6792 18.4784 36.2827L22.8303 31.7693L29.7345 37.8124C30.2326 38.2543 30.8751 38.4988 31.5409 38.4999C31.8327 38.4997 32.1226 38.4539 32.4003 38.3641C32.8539 38.2202 33.262 37.9599 33.5837 37.6092C33.9055 37.2585 34.1298 36.8296 34.2342 36.3652L41.2106 6.01555C41.273 5.74184 41.2597 5.45631 41.1722 5.18956C41.0847 4.92281 40.9263 4.6849 40.7139 4.50133ZM30.2054 10.6888L14.8072 21.7163L6.28216 20.044L30.2054 10.6888ZM16.5001 34.3749V26.2143L20.7609 29.9509L16.5001 34.3749ZM31.5444 35.7499L17.3337 23.289L37.7869 8.62977L31.5444 35.7499Z" fill="#FDFEFF"/>
                                </svg>
                            </div>
                        </a> 
                        <div className='group-support' support-tooltip='Support' onClick={() => setModalActive(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                            <path d="M39.689 36.4375C37.0714 31.9121 33.0375 28.6671 28.3298 27.1288C30.6584 25.7425 32.4676 23.6302 33.4796 21.1162C34.4915 18.6023 34.6502 15.8256 33.9313 13.2127C33.2125 10.5998 31.6557 8.29506 29.5003 6.6525C27.3448 5.00995 24.7097 4.12036 21.9997 4.12036C19.2897 4.12036 16.6546 5.00995 14.4991 6.6525C12.3436 8.29506 10.7869 10.5998 10.068 13.2127C9.34913 15.8256 9.50784 18.6023 10.5198 21.1162C11.5317 23.6302 13.3409 25.7425 15.6695 27.1288C10.9619 28.6653 6.92795 31.9104 4.3103 36.4375C4.2143 36.5941 4.15063 36.7682 4.12304 36.9498C4.09544 37.1313 4.10449 37.3165 4.14964 37.4945C4.19478 37.6725 4.27512 37.8396 4.38591 37.986C4.49669 38.1324 4.63569 38.2552 4.79468 38.347C4.95368 38.4389 5.12946 38.4979 5.31165 38.5207C5.49385 38.5435 5.67876 38.5296 5.85549 38.4798C6.03222 38.43 6.19718 38.3453 6.34063 38.2307C6.48409 38.1161 6.60314 37.9739 6.69077 37.8125C9.92889 32.2163 15.6523 28.875 21.9997 28.875C28.347 28.875 34.0705 32.2163 37.3086 37.8125C37.3962 37.9739 37.5153 38.1161 37.6587 38.2307C37.8022 38.3453 37.9671 38.43 38.1439 38.4798C38.3206 38.5296 38.5055 38.5435 38.6877 38.5207C38.8699 38.4979 39.0457 38.4389 39.2047 38.347C39.3637 38.2552 39.5026 38.1324 39.6134 37.986C39.7242 37.8396 39.8046 37.6725 39.8497 37.4945C39.8949 37.3165 39.9039 37.1313 39.8763 36.9498C39.8487 36.7682 39.785 36.5941 39.689 36.4375ZM12.3747 16.5C12.3747 14.5964 12.9392 12.7355 13.9968 11.1527C15.0544 9.56985 16.5576 8.33619 18.3163 7.6077C20.0751 6.8792 22.0104 6.6886 23.8774 7.05998C25.7445 7.43136 27.4595 8.34805 28.8056 9.69413C30.1517 11.0402 31.0683 12.7552 31.4397 14.6223C31.8111 16.4894 31.6205 18.4246 30.892 20.1834C30.1635 21.9421 28.9299 23.4453 27.347 24.5029C25.7642 25.5605 23.9033 26.125 21.9997 26.125C19.4478 26.1223 17.0012 25.1074 15.1968 23.3029C13.3923 21.4985 12.3774 19.0519 12.3747 16.5Z" fill="#FDFEFF"/>
                            <path d="M34.5 12.5C34.5 18.5193 28.9629 23.5 22 23.5C15.0371 23.5 9.5 18.5193 9.5 12.5C9.5 6.48072 15.0371 1.5 22 1.5C28.9629 1.5 34.5 6.48072 34.5 12.5Z" stroke="url(#paint0_linear_1952_51954)"/>
                            <ellipse cx="35" cy="15.5" rx="2" ry="6.5" fill="white"/>
                            <ellipse cx="9" cy="15.5" rx="2" ry="6.5" fill="#FDFEFF"/>
                            <circle cx="24.5" cy="19.5" r="1.5" fill="#FDFEFF"/>
                            <line x1="25" y1="19.5" x2="32" y2="19.5" stroke="white"/>
                            <defs>
                                <linearGradient id="paint0_linear_1952_51954" x1="22" y1="1" x2="22" y2="24" gradientUnits="userSpaceOnUse">
                                <stop offset="0.44" stopColor="white"/>
                                <stop offset="1" stopColor="#CDCDCD" stopOpacity="0"/>
                                </linearGradient>
                            </defs>
                            </svg>
                        </div>
                        <div className='group-descktop'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                <path d="M35.75 6.875H8.25C7.15598 6.875 6.10677 7.3096 5.33318 8.08318C4.5596 8.85677 4.125 9.90598 4.125 11V30.25C4.125 31.344 4.5596 32.3932 5.33318 33.1668C6.10677 33.9404 7.15598 34.375 8.25 34.375H20.625V37.125H16.5C16.1353 37.125 15.7856 37.2699 15.5277 37.5277C15.2699 37.7856 15.125 38.1353 15.125 38.5C15.125 38.8647 15.2699 39.2144 15.5277 39.4723C15.7856 39.7301 16.1353 39.875 16.5 39.875H27.5C27.8647 39.875 28.2144 39.7301 28.4723 39.4723C28.7301 39.2144 28.875 38.8647 28.875 38.5C28.875 38.1353 28.7301 37.7856 28.4723 37.5277C28.2144 37.2699 27.8647 37.125 27.5 37.125H23.375V34.375H35.75C36.844 34.375 37.8932 33.9404 38.6668 33.1668C39.4404 32.3932 39.875 31.344 39.875 30.25V11C39.875 9.90598 39.4404 8.85677 38.6668 8.08318C37.8932 7.3096 36.844 6.875 35.75 6.875ZM8.25 9.625H35.75C36.1147 9.625 36.4644 9.76987 36.7223 10.0277C36.9801 10.2856 37.125 10.6353 37.125 11V24.75H6.875V11C6.875 10.6353 7.01987 10.2856 7.27773 10.0277C7.53559 9.76987 7.88533 9.625 8.25 9.625ZM35.75 31.625H8.25C7.88533 31.625 7.53559 31.4801 7.27773 31.2223C7.01987 30.9644 6.875 30.6147 6.875 30.25V27.5H37.125V30.25C37.125 30.6147 36.9801 30.9644 36.7223 31.2223C36.4644 31.4801 36.1147 31.625 35.75 31.625Z" fill="#FDFEFF"/>
                            </svg>
                        </div>
                     </div>
                </div> 
            : ''}
            {showGroup && <Modal active={modalActive} setActive={setModalActive}/>}
                  <TawkMessengerReact
                        propertyId='67a9eeb43a842732607c736a'
                        onBeforeLoad ={onBeforeLoad }
                        widgetId='1ijnsosrm'
                        ref={tawkMessengerRef}/>
        </div>
    )   
}

export default LayerPage;