import React, {} from 'react';
import { ButtonProps } from './Interfaces';
import './Button.scss';


export const Button: React.FC<ButtonProps> = ({ onClick, disabled, className, children}) => 
{   
    // console.log('Button перерендер')
    return (
            <button 
                onClick={onClick}
                disabled={disabled}
                className={className}>
                {children}
            </button>
    );
};
