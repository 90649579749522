import React from 'react';
import './Input.scss'

interface InputProps {
  type?: string;
  className?: string;
  placeholder?: string;
  onChange?: (...event: any[]) => void; 
  onBlur?: () => void; 
  value?: string; 
  name?: string; 
  ref?: React.Ref<HTMLInputElement>; 
  autoComplete?: string
}

export const Input: React.FC<InputProps> = ({type, className, placeholder, onChange, onBlur, value, name, ref, autoComplete ='off'}) => 
{
  return (
    <input
        type={type}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        ref={ref}
        autoComplete={autoComplete}
    />
  );
};