import React, {useState} from 'react';
// import {InputForm} from '../input/InputChat';
interface responseProps 
{
    response: string | string[];
    // editMessage: (message: string) => void
} 
const ItemResponse:React.FC<responseProps> = ({response}) => 
{
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const [text, setText] = useState<string | string[]>(response);
    const handleEditMessage = () =>
    {
        setIsClicked(true)
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setText(e.target.value);
    };
        const handleBlur = () => {
        setIsClicked(false);
        // Здесь можно вызвать функцию editMessage, если нужно сохранить изменения
        // editMessage(text);
    };
    return (
            <li className="survey__question-right">
                <div className="survey__question-wrapper">
                    <div onClick={handleEditMessage} className="survey__icon-edit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_1_1748)">
                            <path d="M2.5 14.5501V17.0835C2.5 17.3168 2.68333 17.5001 2.91667 17.5001H5.45C5.55833 17.5001 5.66667 17.4585 5.74167 17.3751L14.8417 8.28346L11.7167 5.15846L2.625 14.2501C2.54167 14.3335 2.5 14.4335 2.5 14.5501ZM17.2583 5.8668C17.5833 5.5418 17.5833 5.0168 17.2583 4.6918L15.3083 2.7418C14.9833 2.4168 14.4583 2.4168 14.1333 2.7418L12.6083 4.2668L15.7333 7.3918L17.2583 5.8668Z" fill="#ADABFF"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_1748">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                        </svg>                        
                    </div>                    
                    {/* <span className="survey__question-text">{response}</span> */}
                    {isClicked ? (
                    <input
                        type="text"
                        
                        value={text}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        autoFocus
                    />
                ) : (
                    <span className="survey__question-text">{text}</span>
                )}
                </div>                
                <div className="survey__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path d="M19 19.0223C17.0693 19.0223 15.4808 18.4108 14.2346 17.1879C12.9885 15.9649 12.3654 14.406 12.3654 12.5112C12.3654 10.6037 12.9885 9.04169 14.2346 7.82502C15.4808 6.60834 17.0693 6 19 6C20.9307 6 22.5192 6.60834 23.7654 7.82502C25.0115 9.04169 25.6346 10.6037 25.6346 12.5112C25.6346 14.406 25.0115 15.9649 23.7654 17.1879C22.5192 18.4108 20.9307 19.0223 19 19.0223ZM6.30766 34C5.66666 34 5.1218 33.7798 4.6731 33.3394C4.22437 32.8991 4 32.3643 4 31.7353V30.2745C4 29.2201 4.28462 28.3017 4.85385 27.5191C5.42309 26.7365 6.16284 26.135 7.07311 25.7148C9.17822 24.7963 11.2077 24.1043 13.1615 23.6387C15.1154 23.1732 17.0615 22.9405 19 22.9405C20.9385 22.9405 22.8795 23.1783 24.8231 23.6539C26.7667 24.1294 28.791 24.8164 30.8961 25.7148C31.8269 26.135 32.5769 26.7365 33.1461 27.5191C33.7154 28.3017 34 29.2201 34 30.2745V31.7353C34 32.3643 33.7756 32.8991 33.3269 33.3394C32.8782 33.7798 32.3333 34 31.6923 34H6.30766Z" fill="#BBB7FF"/>
                    </svg>                      
                </div>
            </li>      
    );
};

export default ItemResponse;